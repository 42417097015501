<template>
    <!-- begin:: Header Topbar -->
    <div class="kt-header__topbar">
        <!--begin: User Bar -->
        <div class="kt-header__topbar-item kt-header__topbar-item--user">
            <div
                class="kt-header__topbar-wrapper"
                id="kt_user_toggle"
                data-toggle="dropdown"
            >
                <div class="kt-header__topbar-user">
                    <span class="kt-header__topbar-welcome kt-hidden-mobile"
                        >Hi,</span
                    >
                    <span class="kt-header__topbar-username kt-hidden-mobile">{{
                        currentUser.name
                    }}</span>
                    <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
                    <span
                        class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
                        >{{
                            currentUser.name
                                ? currentUser.name.charAt(0).toUpperCase()
                                : ""
                        }}</span
                    >
                </div>
            </div>
            <div
                class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
                v-on:click.stop
            >
                <KTDropdownUser></KTDropdownUser>
            </div>
        </div>
        <!--end: User Bar -->
    </div>
    <!-- end:: Header Topbar -->
</template>

<script>
import { mapGetters } from "vuex";
import KTDropdownUser from "@/views/theme/topbar/DropdownUser.vue";
import i18nService from "@/common/i18n.service.js";

export default {
    name: "KTTopbar",
    data() {
        return {
            languageFlag: "",
            languages: i18nService.languages
        };
    },
    computed: {
        ...mapGetters(["currentUser"]),
        getLanguageFlag() {
            return this.onLanguageChanged();
        }
    },
    components: {
        KTDropdownUser
    },
    methods: {
        onLanguageChanged() {
            this.languageFlag = this.languages.find(val => {
                return val.lang === i18nService.getActiveLanguage();
            }).flag;
        }
    }
};
</script>
