<template>
    <li
        class="kt-menu__item"
        aria-haspopup="true"
        ref="gt-menu_item"
        @click="checkIsOpen(menu)"
        :class="getClasses(menu)"
        :data-ktmenu-submenu-toggle="submenuToggle(menu)"
    >
        <KTMenuItemText v-bind:menu="menu"></KTMenuItemText>
        <KTMenuSubmenu
            v-if="menu.submenu"
            v-bind:submenu="menu.submenu"
            v-bind:parentMenu="menu"
        ></KTMenuSubmenu>
    </li>
</template>

<script>
import KTMenuItemText from "@/views/theme/aside/MenuItemText.vue";
import KTMenuSubmenu from "@/views/theme/aside/MenuSubmenu.vue";
import KTToggle from "@/assets/js/toggle.js";

export default {
    name: "KTMenuItem",
    components: {
        KTMenuItemText,
        KTMenuSubmenu
    },
    props: {
        menu: Object
    },
    methods: {
         getClasses(menu){
            let classes = menu.class || {};
            classes['kt-menu__item--submenu'] = menu.submenu;
            classes['kt-menu__item--submenu'] = this.activeMenu(menu);
            return classes;
        },
        activeMenu(input) {
            const paths = Array.isArray(input) ? input : [input];
            return paths.some(conf => {
                if (conf.submenu) {
                    return this.activeMenu(conf.submenu);
                }
                if (conf.page) {
                    const el =this.$parent.$parent.$parent.$el;
                    if(el){
                        el.children[0].click()
                    }
                    // current path starts with this path string
                    return this.$route.path.search(`/${conf.page}`) > -1;
                }
            });
        },
        /**
         * Get submenu toggle type
         * @param menu
         * @returns {string|*}
         */
        submenuToggle(menu) {
            if (menu.hasOwnProperty("toggle")) {
                return menu.toggle;
            }
            return "hover";
        },
        checkIsOpen(menu){
            if(menu.submenu && menu.submenu.length>0 && document.getElementsByTagName('body')[0].classList.contains('kt-aside--minimize')){
                // document.body.className = document.body.className.replace('kt-aside--minimize',"");
                //   const asideToggler = new KTToggle(this.$refs["gt-menu_item"], {
                //         target: "body",
                //         targetState: "kt-aside--minimize",
                //         togglerState: "kt-aside__brand-aside-toggler--active"
                //     });
                //     asideToggler.toggleOff()
                const el = document.getElementById('kt_aside_toggler');
                el.attributes['out'] = true;
                el.click()
            }
        }
    }
};
</script>
