<template>
    <div>
        <!--begin: Head -->
        <div
            class="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            :style="{ backgroundImage: `url(${backgroundImage})` }"
        >
            <div class="kt-user-card__avatar">
                <img
                    class="kt-hidden"
                    alt="Pic"
                    src="@/assets/media/users/300_25.jpg"
                />
                <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
                <span
                    class="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success"
                    >{{
                        currentUser.name
                            ? currentUser.name.charAt(0).toUpperCase()
                            : ""
                    }}</span
                >
            </div>
            <div class="kt-user-card__name">{{ currentUser.name }}</div>
            <!-- <div class="kt-user-card__badge">
                <span class="btn btn-success btn-sm btn-bold btn-font-md"
                    >23 messages</span
                >
            </div> -->
        </div>
        <!--end: Head -->
        <!--begin: Navigation -->
        <div class="kt-notification">
            <!-- <a href="#" class="kt-notification__item">
                <div class="kt-notification__item-icon">
                    <i class="flaticon2-calendar-3 kt-font-success"></i>
                </div>
                <div class="kt-notification__item-details">
                    <div class="kt-notification__item-title kt-font-bold">
                        My Profile
                    </div>
                    <div class="kt-notification__item-time">
                        Account settings and more
                    </div>
                </div>
            </a> -->
            <div class="kt-notification__custom kt-space-between">
                <router-link
                  :to="{ name: 'profile' }"
                  class="btn btn-label btn-label-brand btn-sm btn-bold"
                >
                  {{ $t('PROFILE.PROFILE_BUTTON') }}
                </router-link>
                <a
                  href="#"
                  v-on:click="onLogout()"
                  class="btn btn-label btn-label-brand btn-sm btn-bold"
                >
                  {{ $t('PROFILE.LOG_OUT_BUTTON') }}
                </a>
            </div>
        </div>
        <!--end: Navigation -->
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/store/auth.module";

export default {
    name: "KTDropdownUser",
    methods: {
        onLogout() {
            this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
        }
    },
    computed: {
        ...mapGetters(["currentUser"]),
        backgroundImage() {
            return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
        }
    }
};
</script>
